import staff1 from "../images/staff1.jpg"
import staff2 from "../images/staff2.jpg"
import staff3 from "../images/staff3.jpg"
import staff4 from "../images/staff4.jpg"
export const userData = [{
    userID: "1",
    title: "Mrs",
    firstName: "Aisha",
    middleName: "Ibrahim",
    surname: "Umar",
    gender: "Female",
    phoneNumber: "08034564345",
    emailAddress: "maumar.aisha@enyimbahospital.com",
    role: "Matron",
    aboutDoctor: null,
    researchGate: "",
    academia: "",
    googleScholar: "",
    twitter: "",
    linkedin: "",
    experience: "",
    passport: staff1,
},{
    userID: "2",
    title: "Mr",
    firstName: "Adam",
    middleName: "Yau",
    surname: "Musa",
    gender: "Male",
    phoneNumber: "08056364345",
    emailAddress: "adam.musa@enyimbahospital.com",
    role: "Doctor",
    aboutDoctor: "Adam work experience spans private and government medical, public health and allied institutions; with  job  roles  ranging from Clinical, Advisory, Project lead, Trainer and Facilitator capacities  to mention a few.",
    researchGate: "",
    academia: "",
    googleScholar: "",
    twitter: "",
    linkedin: "",
    experience: "",
    passport: staff2,
},{
    userID: "3",
    title: "Dr",
    firstName: "Abdulmutallib",
    middleName: "",
    surname: "Alhassan",
    gender: "Male",
    phoneNumber: "08034564301",
    emailAddress: "abdul.mutallib@enyimbahospital.com",
    role: "Doctor",
    aboutDoctor: "Abdulmutallib work experience spans private and government medical, public health and allied institutions; with job roles ranging from Clinical, Advisory, Project lead, Trainer and Facilitator capacities to mention a few.",
    researchGate: "",
    academia: "",
    googleScholar: "",
    twitter: "",
    linkedin: "",
    experience: "",
    passport: staff3,
},{
    userID: "4",
    title: "Miss",
    firstName: "Fatima",
    middleName: "Ibrahim",
    surname: "Abubakar",
    gender: "Female",
    phoneNumber: "08022564345",
    emailAddress: "fatima.ibrahim@enyimbahospital.com",
    role: "Nurse",
    aboutDoctor: "Fatima work experience spans private and government medical, public health and allied institutions; with job roles ranging from Clinical, Advisory, Project lead, Trainer and Facilitator capacities to mention a few.",
    researchGate: "",
    academia: "",
    googleScholar: "",
    twitter: "",
    linkedin: "",
    experience: "",
    passport: staff4,
},
];


const specializations = [{
    specializationID: 1,
    specialisationName: "Neurologist",
    consultationFee: 10000,
    specialisationDescription: "",
    submittedBy: "E0577",
    submittedOn:"2023-09-04 15:08:25",
},{
    specializationID: 1,
    specialisationName: "Neurologist",
    consultationFee: 10000,
    specialisationDescription: "",
    submittedBy: "E0577",
    submittedOn:"2023-09-04 15:08:25",
},{
    specializationID: 1,
    specialisationName: "Neurologist",
    consultationFee: 10000,
    specialisationDescription: "",
    submittedBy: "E0577",
    submittedOn:"2023-09-04 15:08:25",
},{
    specializationID: 1,
    specialisationName: "Neurologist",
    consultationFee: 10000,
    specialisationDescription: "",
    submittedBy: "E0577",
    submittedOn:"2023-09-04 15:08:25",
},
];