import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {userData} from "../../../resources/doctors_data";
import {news_data} from "../../../resources/services_data";
import {formatDateAndTime, serverLink} from "../../../resources/url";
import axios from "axios";
import {setGeneralDetails, setNewsDetails} from "../../../actions/setactiondetails";
import {connect} from "react-redux";
function NewsArea(props) {
    const [loading, setLoading] = useState(props.newsList.length < 1);
    const [newsData, setNewsData] = useState(props.isHome ? [...props.newsList.slice(0,2)] : [...props.newsList])
    const getData = async () => {
        await axios
            .get(`${serverLink}news/list/active`)
            .then((result) => {
                const data = result.data;
                setNewsData(data);
                props.setOnNewsDetails(data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    };


    useEffect(() => {
        getData();
    }, [""]);

    return (
        <div className="our_department_area">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="doctors_title mb-55">
                            <h3>Latest Publications/News</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        newsData.length > 0 ?
                            newsData.map((item, index)=>{
                                const slug = item.title.trim().replace(/\s+/g, '-').toLowerCase();
                                const date = new Date(item.submittedOn);
                                const day = date.getDate();
                                const monthName = formatDateAndTime(item.submittedOn, 'short_month');
                                const year = formatDateAndTime(item.submittedOn, 'year_only');
                                return (
                                    <div key={index}  className="col-md-6 shadow">
                                        <article className="blog_item">
                                            <Link to={`/news/${slug}`} className="blog_item_date">
                                            <div className="blog_item_img">
                                                <img className="card-img rounded-0" src={`${serverLink}public/uploads/news_uploads/${item.image}`} alt=""/>
                                                <Link to={`/news/${slug}`} className="blog_item_date">
                                                    <h3>{day}</h3>
                                                    <p>{monthName}</p>
                                                </Link>
                                            </div>
                                            </Link>
                                            <div className="blog_details" style={{textAlign: 'justify', height: '270px'}}>
                                                <Link className="d-inline-block" to={`/news/${slug}`} style={{height: '55px'}}>
                                                    <h4>{item.title?.slice(0, 80) + (item.title?.length > 80 ? "..." : "")}</h4>
                                                </Link>
                                                <p dangerouslySetInnerHTML={{__html: item.description?.slice(0, 160) + (item.description?.length > 160 ? "..." : "")}}></p>
                                                <div className="d-flex justify-content-between align-items-md-center">
                                                    <ul className="blog-info-link">
                                                        <li><a href="#"><i className="fa fa-user"></i> {item.submittedBy}</a></li>
                                                        <li><a href="#"><i className="fa fa-eye"></i> {item.viewCount}</a></li>
                                                    </ul>
                                                    <Link to={`/news/${slug}`} className="text-info">Read More</Link>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                )
                            }) : <div className="col-md-12 text-center"><h3>No Record Found</h3></div>
                    }
                </div>
            </div>
            <div className="text-center mt-2">
                {
                    props.isHome ?
                        <Link to="/news" className="boxed-btn3-white-2">Explore More</Link> :<></>
                }
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        newsList: state.NewsDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        },
        setOnNewsDetails: (p) => {
            dispatch(setNewsDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewsArea);