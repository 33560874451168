import {Link} from "react-router-dom";
import {useState} from "react";
import {userData} from "../../../resources/doctors_data";
import coming from "../../../images/coming-soon.avif"
import coming2 from "../../../images/soon.avif"
export default function DoctorsArea(props) {
    // const [doctorsData, setDoctorsData] = useState(props.isHome ? [...userData.slice(0,8)] : [...userData])
    const [doctorsData, setDoctorsData] = useState(props.isHome ? [] : [])

    return (
        <div className="expert_doctors_area">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="doctors_title mb-55">
                            <h3>Doctors</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        doctorsData.length > 0 ?
                            doctorsData.map((item, index)=>{
                                const doctorName = item.firstName+" "+item.middleName+" "+item.surname;
                                const slug = doctorName.trim().replace(/\s+/g, '-').toLowerCase();
                                return (
                                    <div key={index} className="single_expert col-md-3 mb-5">
                                        <div className="expert_thumb">
                                            <Link to={`/doctor/${slug}`}>
                                                <img src={item.passport} alt=""/>
                                            </Link>
                                        </div>
                                        <div className="experts_name text-center">
                                            <Link to={`/doctor/${slug}`}>
                                                <h4>{doctorName}</h4>
                                            </Link>
                                            <span>Neurologist</span>
                                        </div>
                                    </div>
                                )
                            }) : <div className="col-md-12 text-center">
                                <p>Doctors data will be available soon!</p>
                                    {/*<img className="w-100" src={coming2}/>*/}
                                {/*<h3>No Record Found</h3>*/}
                                </div>
                    }
                </div>
            </div>
            <div className="text-center mt-2">
                {
                    props.isHome ?
                        <Link to="/doctors" className="boxed-btn3-white-2">Explore More</Link> :<></>
                }
            </div>
        </div>
    )
}