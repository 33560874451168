import PageTitle from "../common/pagetitle/pagetitle";
import {useEffect, useState} from "react";
import {news_data} from "../../resources/services_data";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {formatDateAndTime, serverLink} from "../../resources/url";
import axios from "axios";

export default function NewsDetails() {
    const navigate = useNavigate();
    const location = useLocation();
    const slug = useParams();
    const title =  slug.slug;
    const [isLoading, setIsLoading] = useState(true);
    const [newsData, setNewsData] = useState([])
    const [newsList, setNewsList] = useState([]);

    const getData = async () => {
        let new_id = title;
        await axios.get(`${serverLink}news/list/active`)
            .then((res) => {
                if (res.data.length > 0){
                    const record = res.data;
                    let details = record.filter(e=>e.Slug.toString()  === new_id.toString())
                    let other_news = record.filter(e=>e.Slug.toString()  !== new_id.toString())
                    setNewsData(details);
                    setNewsList(other_news);
                    updateViewCount(details[0].newsID)
                }else{
                    navigate(`/news`)
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    const updateViewCount = async (id) => {
        const sendData = {NewsID: id};
        await axios.patch(`${serverLink}news/update/view-count`, sendData)
            .then((result) => {}).catch((err) => {console.log("NETWORK ERROR");});
    };

    const scrollTop = async () => {
        await window.scrollTo(0, 0);
    };

     useEffect(() => {
         if (slug.slug === "") {
             navigate("/news");
         }
         getData();
         scrollTop();
         }, [location]);
    return ( isLoading ? <></> :
        <>
            <PageTitle title={"News Details"}/>
            <section className="blog_area single-post-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 posts-list">
                            <div className="single-post">
                                <div className="feature-img">
                                    <img className="img-fluid img-thumbnail" src={`${serverLink}public/uploads/news_uploads/${newsData[0].image}`} alt=""/>
                                </div>
                                <div className="blog_details">
                                    <h2>{newsData[0].title}</h2>
                                    <ul className="blog-info-link mt-3 mb-4">
                                        <li><a href="#"><i className="fa fa-user"></i> {newsData[0].submittedBy}</a></li>
                                        <li><a href="#"><i className="fa fa-calendar-alt"></i> {formatDateAndTime(newsData[0].submittedOn, 'date_and_time')}</a></li>
                                    </ul>
                                    <div className="text-justify">
                                        <p className="sample-text" dangerouslySetInnerHTML={{__html: newsData[0].description}}></p>
                                    </div>

                                </div>
                            </div>
                            <div className="navigation-top">
                                <div className="d-sm-flex justify-content-between text-center">
                                    <p className="like-info"><span className="align-middle"><i className="fa fa-eye"></i></span> {newsData[0].viewCount}</p>
                                    <div className="col-sm-4 text-center my-2 my-sm-0">
                                    </div>
                                    <ul className="social-icons">
                                        <li><a target="_blank" href="#"><i className="fa-solid ti-facebook"></i></a></li>
                                        <li><a target="_blank"  href="#"><i className="fa ti-twitter"></i></a></li>
                                        <li><a target="_blank"  href="#"><i className="fa ti-dribbble"></i></a></li>
                                        <li><a target="_blank"  href="#"><i className="fa ti-behance"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="blog_right_sidebar">

                                <aside className="single_sidebar_widget popular_post_widget">
                                    <h3 className="widget_title">Recent Post</h3>
                                    {
                                        newsList.length > 0 ?
                                            newsList.map((item, index)=> {
                                                const slug = item.Slug;
                                                const date = new Date(item.submittedOn);
                                                const day = date.getDate();
                                                const monthName = formatDateAndTime(item.submittedOn, 'short_month');
                                                const year = formatDateAndTime(item.submittedOn, 'year_only');
                                                return (
                                                    <div key={index} className="media post_item">
                                                        <div style={{paddingLeft: '15px', paddingRight: '15px', color: 'white !important', backgroundColor: '#83cee7'}}>
                                                            <Link to={`/news/${slug}`}  onClick={()=> {
                                                                setIsLoading(true);
                                                            }} className="blog_item_date">
                                                                <h3 style={{ color: '#FFFFFF'}}>{day}</h3>
                                                                <p style={{ color: '#FFFFFF'}}>{monthName}</p>
                                                            </Link>
                                                        </div>
                                                        <div className="media-body">
                                                            <Link to={`/news/${slug}`}  onClick={()=> {
                                                                setIsLoading(true);
                                                            }}>
                                                                <h3>{item.title?.slice(0, 40) + (item.title?.length > 40 ? "..." : "")}</h3>
                                                            </Link>
                                                            <p>{formatDateAndTime(newsData[0].submittedOn, 'date_and_time')}</p>
                                                        </div>
                                                    </div>
                                                )
                                            }) : <div className="col-md-12 text-center"><h3>No Record Found</h3></div>
                                    }
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

