import PageTitle from "../common/pagetitle/pagetitle";
import logo from "../../images/logo-color.png";
import React from "react";
import { Link } from "react-router-dom";

export default function Signup() {
  return (
    <>
      <div className="container card mb-5">
        <div className="col-md-12">
          <form id="test-form" className="white-popup-block mb-5 mt-5">
            <div className="popup_box ">
              <div className="popup_inner">
                <div className="footer_logo justify-content-center d-flex mb-4">
                  <a href="#">
                    <img src={logo} width={200} alt="Logo" />
                  </a>
                </div>
                <h3 className="text-dark">Create an Account</h3>
                <form action="#">
                  <div className="row">
                    <div className="col-xl-12 mb-3">
                      <input type="text" placeholder="First Name" required />
                    </div>
                    <div className="col-xl-12 mb-3">
                      <input type="text" placeholder="Surname" required />
                    </div>
                    <div className="col-xl-12 mb-3">
                      <input
                        type="number"
                        placeholder="Phone Number"
                        required
                      />
                    </div>
                    <div className="col-xl-12 mb-3">
                      <input
                        type="email"
                        placeholder="Email Address"
                        required
                      />
                    </div>
                    <div className="col-xl-12 mb-3">
                      <input type="password" placeholder="Password" />
                    </div>

                    <div className="col-xl-12 mb-3">
                      <input type="password" placeholder="Confirm Password" />
                    </div>

                    <div className="col-xl-12 mb-3">
                      <button type="submit" className="boxed-btn3">
                        Signup
                      </button>
                    </div>
                    <div
                      className="col-xl-12 mb-3 text-info"
                      style={{ textAlign: "left" }}
                    >
                      <label htmlFor="forgot">
                        <Link to="/login">
                          Already have an account?{" "}
                          <b className="text-dark">Login here</b>
                        </Link>
                      </label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
