import PageTitle from "../common/pagetitle/pagetitle";
import React from "react";
import ServiceArea from "../homepage/section/our-services";

export default function Services() {
    return (
        <>
            <PageTitle title="Our Services"/>
            <ServiceArea/>
        </>
    )
}
