import React, { useEffect, useState } from "react";
import Slider from "./section/slider";
import { connect } from "react-redux";
import PageName from "../common/pagename/pagename";
import DataLoader from "../common/dataLoader/dataLoader";
import Badge from "./section/badge";
import WelcomeArea from "./section/welcome-area";
import ServiceArea from "./section/our-services";
import DoctorsArea from "./section/doctors-area";
import NewsArea from "./section/news-area";

function Homepage(props) {
  const [refreshed, setRefreshed] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const reloadCount = sessionStorage.getItem("reloadCount");
    if (reloadCount < 2) {
      sessionStorage.setItem("reloadCount", String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem("reloadCount");
    }
  }, [refreshed]);

  return (
    <>
      {loading ? (
        <section className="space-ptb position-relative">
          <div className="container" style={{ height: "400px" }}>
            <div className="page-header d-print-none">
              <DataLoader />
            </div>
          </div>
        </section>
      ) : (
        <>
          <PageName page={"Home"} />
          <Slider sliderList={props.sliderList} />
          <Badge />
          <WelcomeArea />
          <ServiceArea isHome={true} />
          <DoctorsArea isHome={true} />
          <NewsArea isHome={true} />
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    departmentList: state.DepartmentListDetails,
    newsList: state.NewsDetails,
    sliderList: state.SliderDetails,
    galleryList: state.GalleryDetails,
  };
};
export default connect(mapStateToProps, null)(Homepage);
