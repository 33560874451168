import PageTitle from "../common/pagetitle/pagetitle";
import {useEffect, useState} from "react";
import {servicesData} from "../../resources/services_data";
import {useNavigate, useParams} from "react-router-dom";

export default function ServiceDetails() {
    const navigate = useNavigate();
    const slug = useParams();
    const title =  slug.slug.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    const [serviceData, setServiceData] = useState(servicesData.filter(e=>e.title === title))

    useEffect(() => {
        if (slug.slug === "") navigate("/services");
    }, []);
    return (
        <>
            <PageTitle title={title}/>
            <section className="sample-text-area">
                <div className="container box_1170" style={{textAlign: 'justify'}}>
                    <h3 className="text-heading">{serviceData[0].caption}</h3>
                    <p className="sample-text" dangerouslySetInnerHTML={{__html: serviceData[0].text}}></p>
                </div>
            </section>
        </>
    )
}