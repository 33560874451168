import PageTitle from "../common/pagetitle/pagetitle";
import {useEffect, useState} from "react";
import {servicesData} from "../../resources/services_data";
import {Link, useNavigate, useParams} from "react-router-dom";
import {userData} from "../../resources/doctors_data";
import doctorImage from "../../images/doctor.jpeg";
import nurseImage from "../../images/nurse.jpeg";

export default function DoctorDetails() {
    const navigate = useNavigate();
    const slug = useParams();
    const name =  slug.slug.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    const [doctorData, setDoctorData] = useState(userData.filter(e=> (e.firstName+" "+e.middleName+" "+e.surname).toLowerCase() === name.toLowerCase()))

    useEffect(() => {
        if (slug.slug === "") navigate("/doctors");
    }, []);

    const showImages = () => {
      return !doctorData[0]?.passport ? doctorData[0]?.gender === "Male" ? doctorImage : nurseImage : doctorData[0]?.passport;
    }
    return (
        <>
            <PageTitle title={name}/>
            <section className="sample-text-area">
                <div className="container box_1170" style={{textAlign: 'justify'}}>
                    <div className="row">
                        <div className="col-md-3 mb-4">
                            <img src={showImages()} className="img-fluid w-100" alt={doctorData[0]?.firstName+" "+doctorData[0]?.middleName+" "+doctorData[0]?.surname}/>
                        </div>
                        <div className="col-md-9">
                            <h2>{doctorData[0]?.firstName} {doctorData[0]?.middleName} {doctorData[0]?.surname} </h2>
                            <i>Neurologist</i><br/>
                            <span style={{fontSize: '13px'}}>Specializations: Ears, dentist, pathologies</span><br/>
                            <div className="doctor-details-contact">
                                <hr/>
                                <h5 className="text-muted">Contact info</h5>
                                <hr/>
                                <ul>
                                    <li>
                                        <i className="fa fa-phone"></i> &nbsp;
                                        Call : {doctorData[0]?.phoneNumber}
                                    </li>
                                    <li>
                                        <i className="fa fa-mail-bulk"></i> &nbsp;
                                        <a className="text-secondary"
                                           target="_blank"
                                           href={`mailto:${doctorData[0]?.emailAddress}`}
                                           style={{fontSize: '14px'}}>{doctorData[0]?.emailAddress}</a>
                                    </li>
                                    <li>
                                        <i className="fa fa-location-pin"></i> &nbsp;
                                        4th Floor, 408 No Chamber
                                    </li>
                                </ul>
                            </div>
                            <div className="doctor-details-work mt-3">
                                <div className="col-sm-3 col-lg-3">
                                    <div className="header-top-item">
                                        <div className="header-top-right">
                                            <ul className="d-flex align-content-between">
                                                <li className="mr-3">
                                                    <a target="_blank" href={doctorData[0]?.twitter}>
                                                        <i className="fa-solid ti-twitter-alt"></i>
                                                    </a>
                                                </li>
                                                <li className="mr-3">
                                                    <a target="_blank" href={doctorData[0]?.facebook}>
                                                        <i className="fa ti-facebook"></i>
                                                    </a>
                                                </li>
                                                <li className="mr-3">
                                                    <a target="_blank" href={doctorData[0]?.linkedin}>
                                                        <i className="fa ti-linkedin"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div><h5 className="text-muted">Biography</h5></div>
                            <hr/>
                            <p className="sample-text"
                               dangerouslySetInnerHTML={{__html: doctorData[0]?.aboutDoctor ?? "No Record"}}></p>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}