import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./navigationbar.css";
import logo from "../../../images/logo-color.png";
import enyimbaBlack from "../../../images/enyimba-black.png";
import enyimbaWhite from "../../../images/enyimba-black.png";
import {
  projectEmail,
  projectFacebook,
  projectLinkedIn,
  projectPhone,
  projectTwitter,
} from "../../../resources/url";

function NavigationBar(props) {
  const location = useLocation();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  const dismissClick = () => {
    document.getElementById("ToggleNavBar").click();
  };

  const logoStyle = {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "100px",
  };

  const imageStyle1 = {
    marginRight: "20px", // Adjust the margin as needed
  };
  const imageStyle2 = {
    marginRight: "5px", // Adjust the margin as needed
  };

  return (
    <header>
      <div className="header-area ">
        <div className="header-top_area">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-md-6 ">
                <div className="social_media_links">
                  <a href={projectLinkedIn} target="_blank">
                    <i className="fa ti-linkedin"></i>
                  </a>
                  <a href={projectFacebook} target="_blank">
                    <i className="fa ti-facebook"></i>
                  </a>
                  <a href={projectTwitter} target="_blank">
                    <i className="fa-solid ti-twitter-alt"></i>
                  </a>
                </div>
              </div>
              <div className="col-xl-6 col-md-6">
                <div className="short_contact_list">
                  <ul>
                    <li>
                      <a href="#">
                        {" "}
                        <i className="fa fa-envelope"></i> {projectEmail}
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        {" "}
                        <i className="fa fa-phone"></i> {projectPhone}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="sticky-header" className="main-header-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-2 col-lg-2">
                <div className="logo">
                  <div style={logoStyle}>
                    <a href="/">
                      <img src={logo} width={140} alt="Logo" />
                    </a>
                    <a
                      href="httpS://enyimba.roseikonnehospital.com/"
                      target="_blank"
                    >
                      <img src={enyimbaBlack} width={140} alt="Logo" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-8">
                <div className="main-menu  d-none d-lg-block">
                  <nav>
                    <ul id="navigation">
                      <li>
                        <a className="active" href="/">
                          Home
                        </a>
                      </li>
                      <li>
                        <Link to="/services">Services</Link>
                      </li>
                      <li>
                        <Link to="/doctors">Doctors</Link>
                      </li>
                      <li>
                        <Link to="/gallery">Gallery</Link>
                      </li>
                      <li>
                        <a href="#">
                          More <i className="ti-angle-down"></i>
                        </a>
                        <ul className="submenu">
                          <li>
                            <Link to="/news">News</Link>
                          </li>
                          <li>
                            <Link to="/about">About</Link>
                          </li>
                          <li>
                            <Link to="/contact">Contact</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a
                            target="_blank"
                            href="https://patient.roseikonnehospital.com/"
                        >
                          Login
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>

              <div className="col-xl-3 col-lg-3 d-none d-lg-block">
                <div className="Appointment">
                  <div className="book_btn d-none d-lg-block">
                    <a
                        target="_blank"
                        href="https://patient.roseikonnehospital.com/"
                    >
                      Request Appointment
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="mobile_menu d-block d-lg-none"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default NavigationBar;
