import PageTitle from "../common/pagetitle/pagetitle";
import logo from "../../images/logo_black.png";
import React from "react";
import {Link} from "react-router-dom";

export default function ForgotPassword() {
    return (
        <>
            <div className="container card mb-5">
                <form id="test-form" className="white-popup-block mb-5 mt-5">
                    <div className="popup_box ">
                        <div className="popup_inner">
                            <div className="footer_logo justify-content-center d-flex mb-4">
                                <a href="#">
                                    <img src={logo} width={200} alt="Logo"/>
                                </a>
                            </div>
                            <h3 className="text-dark">Reset Password</h3>

                            <form action="#">
                                <div className="row">
                                    <div className="col-xl-12 mb-3">
                                        <input type="email"  placeholder="Email"/>
                                    </div>
                                    <div className="col-xl-12 mb-3">
                                        <button type="submit" className="boxed-btn3">Login</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
