import PageTitle from "../common/pagetitle/pagetitle";
import ServiceArea from "../homepage/section/our-services";
import pics1 from "../../images/pics1.jpg";
import pics2 from "../../images/pics2.jpg";
import pics3 from "../../images/pic3.png";
export default function About() {
  return (
    <>
      <PageTitle title="About" />
      <div className="welcome_docmed_area">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="welcome_thumb">
                <div className="thumb_1">
                  <img src={pics3} alt="Image1" />
                </div>
                <div className="thumb_2">
                  <img src={pics2} alt="Image2" />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="welcome_docmed_info">
                <h2>Welcome to Da-Rose Ikonne Hospital</h2>
                <h3>
                  Best Care For Your <br />
                  Good Health
                </h3>
                <p className="text-justify">
                  Discover Da-Rose Ikonne Hospital, where a rich legacy of
                  compassionate care meets the expertise of dedicated healthcare
                  professionals, fostering a commitment to community well-being
                  and setting new benchmarks in the healthcare industry.
                </p>
                <p>
                  Explore Enyimba Hospital, our sister facility at No 11 Ngozi
                  Ave, Aba.
                  <br />
                  <a href="#"> Visit Enyimba Hospital</a>
                </p>
                <ul>
                  <li>
                    <i className="flaticon-right"></i> State-of-the-art Medical
                    Equipment.
                  </li>
                  <li>
                    <i className="flaticon-right"></i> Highly Qualified and
                    Experienced Medical Staff.
                  </li>
                  <li>
                    <i className="flaticon-right"></i> Comprehensive
                    Specialization in Various Healthcare Disciplines
                  </li>
                  <li>
                    <i className="flaticon-right"></i> Comfortable and
                    Supportive Environment for Patients and Visitors
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="business_expert_area">
        <div className="business_tabs_area">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 mb-3 mt-3">
                <ul className="nav" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Excellent Services
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="profile-tab"
                      data-toggle="tab"
                      href="#profile"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      Qualified Doctors
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="contact-tab"
                      data-toggle="tab"
                      href="#contact"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      Emergency Departments
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="border_bottom">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div className="row align-items-center">
                  <div className="col-xl-6 col-md-6">
                    <div className="business_info">
                      <div className="icon">
                        <i className="flaticon-first-aid-kit"></i>
                      </div>
                      <h3>
                        {" "}
                        Setting the Standard for Excellence in Healthcare
                        Services
                      </h3>
                      <p className="text-justify">
                        Da-Rose Ikonne Hospital sets the gold standard for
                        healthcare excellence, delivering comprehensive medical
                        care, state-of-the-art facilities, and a patient-centric
                        approach with a commitment to efficiency and community
                        engagement.
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-6 col-md-6">
                    <div className="business_thumb">
                      <img src="img/about/about-2.jpeg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <div className="row align-items-center">
                  <div className="col-xl-6 col-md-6">
                    <div className="business_info">
                      <div className="icon">
                        <i className="fa fa-user-doctor"></i>
                      </div>
                      <h3>Distinguished Excellence in Qualified Doctors</h3>
                      <p className="text-justify">
                        Da-Rose Ikonne Hospital takes pride in its team of
                        highly qualified doctors, whose expertise, compassion,
                        and dedication define the pinnacle of healthcare
                        standards, ensuring patients receive top-notch medical
                        care with precision and care.
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-6 col-md-6">
                    <div className="business_thumb">
                      <img src="img/about/about-2.jpeg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="contact"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                <div className="row align-items-center">
                  <div className="col-xl-6 col-md-6">
                    <div className="business_info">
                      <div className="icon">
                        <i className="fa fa-microscope"></i>
                      </div>
                      <h3>Cutting-Edge Excellence in Medical Equipment</h3>
                      <p className="text-justify">
                        Da-Rose Ikonne Hospital distinguishes itself with
                        cutting-edge excellence in medical equipment, ensuring
                        advanced diagnostics and treatments, and prioritizing
                        patient well-being through the use of state-of-the-art
                        technologies and innovative medical equipment.
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-6 col-md-6">
                    <div className="business_thumb">
                      <img src="img/about/about-2.jpeg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
