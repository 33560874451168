import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../images/logo-white.png";
import enyimbaWhite from "../../../images/enyimba-white.png";
import {
  formatDateAndTime,
  projectAddress,
  projectEmail,
  projectFacebook,
  projectInstagram,
  projectLinkedIn,
  projectLogo,
  projectName,
  projectPhone,
  projectTwitter,
} from "../../../resources/url";

export default function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="footer_top">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-md-6 col-lg-4">
                <div className="footer_widget">
                  <div className="footer_logo">
                    <a href="/">
                      <img src={logo} width={180} alt="Logo" />
                    </a>
                  </div>
                  <div className="footer_logo">
                    <a
                      href="httpS://enyimba.roseikonnehospital.com/"
                      target="_blank"
                    >
                      <img src={enyimbaWhite} width={180} alt="Logo" />
                    </a>
                  </div>
                  <p>
                    Da-Rose Ikonne Hospital combines a legacy of compassionate
                    care with dedicated healthcare professionals, setting new
                    benchmarks in the industry and prioritizing community
                    well-being.
                  </p>
                  <div className="socail_links">
                    <ul>
                      <li>
                        <a href={projectFacebook}>
                          <i className="ti-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href={projectTwitter}>
                          <i className="ti-twitter-alt"></i>
                        </a>
                      </li>
                      <li>
                        <a href={projectInstagram}>
                          <i className="fa fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 offset-xl-1 col-md-6 col-lg-3">
                <div className="footer_widget">
                  <h3 className="footer_title">Our Services</h3>
                  <ul>
                    <li>
                      <Link to="/service/eye-care">Eye Care</Link>
                    </li>
                    <li>
                      <Link to="/service/skin-surgery">Skin Care</Link>
                    </li>
                    <li>
                      <Link to="/service/paediatric-care">Paediatric Care</Link>
                    </li>
                    <li>
                      <Link to="#">Medicine</Link>
                    </li>
                    <li>
                      <Link to="/service/dental-care">Dental</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-2 col-md-6 col-lg-2">
                <div className="footer_widget">
                  <h3 className="footer_title">Useful Links</h3>
                  <ul>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/news">News</Link>
                    </li>
                    <li>
                      <Link to="/doctors">Doctors</Link>
                    </li>
                    <li>
                      <Link to="/gallery"> Gallery</Link>
                    </li>
                    <li>
                      <Link to="/login"> Patient Login</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-lg-3">
                <div className="footer_widget">
                  <h3 className="footer_title">Contact</h3>
                  <p>
                    {projectAddress} <br />
                    {projectPhone} <br />
                    {projectEmail}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-right_text">
          <div className="container">
            <div className="footer_border"></div>
            <div className="row">
              <div className="col-xl-12">
                <p className="copy_right text-center">
                  Copyright &copy; {formatDateAndTime(new Date(), "year_only")}{" "}
                  {projectName} All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
