import { Link } from "react-router-dom";
import pics1 from "../../../images/pics1.jpg";
import pics2 from "../../../images/pics2.jpg";
import pics3 from "../../../images/pic3.png";
export default function WelcomeArea() {
  return (
    <div className="welcome_docmed_area">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <div className="welcome_thumb">
              <div className="thumb_1">
                <img src={pics3} alt="Image1" />
              </div>
              <div className="thumb_2">
                <img src={pics2} alt="Image2" />
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="welcome_docmed_info">
              <h2>Welcome to Da-Rose Ikonne Hospital</h2>
              <h3>
                Best Care For Your <br />
                Good Health
              </h3>
              <p className="text-justify">
                Discover Da-Rose Ikonne Hospital, where a rich legacy of
                compassionate care meets the expertise of dedicated healthcare
                professionals, fostering a commitment to community well-being
                and setting new benchmarks in the healthcare industry.
              </p>
              <p>
                Explore Enyimba Hospital, our sister facility at No 11 Ngozi
                Ave, Aba.
                <br />
                <a href="#"> Visit Enyimba Hospital</a>
              </p>
              <ul>
                <li>
                  <i className="flaticon-right"></i> State-of-the-art Medical
                  Equipment.
                </li>
                <li>
                  <i className="flaticon-right"></i> Highly Qualified and
                  Experienced Medical Staff.
                </li>
                <li>
                  <i className="flaticon-right"></i> Comprehensive
                  Specialization in Various Healthcare Disciplines
                </li>
              </ul>
              <Link to="/about" className="boxed-btn3-white-2">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
