import PageTitle from "../common/pagetitle/pagetitle";
import NewsArea from "../homepage/section/news-area";
import React from "react";

export default function News() {
    return (
        <>
            <PageTitle title="News" />
            <NewsArea isHome={false}/>
        </>
    )
}
