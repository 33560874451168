import React, { useEffect, useState } from "react";
import {
  setDepartmentListDetails,
  setGalleryDetails,
  setHomeSliderDetails,
  setNewsDetails,
} from "./actions/setactiondetails";
import { connect } from "react-redux";
import axios from "axios";
import { serverLink } from "./resources/url";
import PageRoutes from "./component/pageroutes/pageroutes";
import { browserName, isMobile } from "react-device-detect";
import HomeContentLoader from "./component/common/homeContentLoader/homeContentLoader";

function App(props) {
  // const [isLoading, setIsLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(
    !!(
      props.departmentList.length > 0 &&
      props.newsList > 0 &&
      props.sliderList > 0
    )
  );

  const getLocationData = async () => {
    await axios
      .get("https://geolocation-db.com/json/")
      .then(async (result) => {
        await getVisitorData(result.data);
      })
      .catch((e) => {
        console.log("Error Getting User Details");
      });
  };

  const getVisitorData = async (locData) => {
    let sendData = {
      IPAddress: locData.IPv4,
      Country: locData.country_name,
      lat: locData.latitude,
      lng: locData.longitude,
      IsMobileRequest: isMobile ? "1" : "0",
      UserAgent: browserName,
    };
    await axios
      .post(`${serverLink}general/website/visit-hit`, sendData)
      .then((data) => {})
      .catch((err) => {
        console.log("Error Adding User Details");
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(`${serverLink}general/website/content`);
        props.setOnNewsDetails(result.data.News);
        props.setOnSliderDetails(result.data.Slider);
        props.setOnGalleryDetails(result.data.Gallery);
      } catch (error) {
        console.log("Error fetching content", error);
      } finally {
        setTimeout(() => {
          console.log("Setting isLoading to false after 50 seconds");
          setIsLoading(false);
        }, 500);
      }
    };

    fetchData().then((r) => {});
  }, []);

  useEffect(() => {
    // getData().then((r) => {});
    getLocationData().then((r) => {});
  }, [""]);

  return (
    <div className="App">
      {isLoading ? <HomeContentLoader /> : <PageRoutes />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    departmentList: state.DepartmentListDetails,
    newsList: state.NewsDetails,
    sliderList: state.SliderDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnDepartmentListDetails: (p) => {
      dispatch(setDepartmentListDetails(p));
    },
    setOnNewsDetails: (p) => {
      dispatch(setNewsDetails(p));
    },
    setOnSliderDetails: (p) => {
      dispatch(setHomeSliderDetails(p));
    },
    setOnGalleryDetails: (p) => {
      dispatch(setGalleryDetails(p));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
