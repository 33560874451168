import {servicesData} from "../../../resources/services_data";
import {useState} from "react";
import {Link} from "react-router-dom";

export default function ServiceArea(props) {
    const [serviceData, setServiceData] = useState(props.isHome ? [...servicesData.slice(0,6)] : [...servicesData])
    return (
        <div className="our_department_area">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="section_title text-center mb-55">
                            <h3>Our Services</h3>
                            <p>Dedicated to Your Health: Explore Our Comprehensive Services <br/> </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        serviceData.length > 0 ?
                            serviceData.map((item, index)=>{
                                const slug = item.title.trim().replace(/\s+/g, '-').toLowerCase();
                                return (
                                    <div key={index} className="col-xl-4 col-md-6 col-lg-4">
                                        <div className="single_department">
                                            <div className="department_thumb text-center">
                                                <span className={`fa ${item.icon} mt-3`} style={{fontSize: '80px'}}></span>
                                            </div>
                                            <div className="department_content text-center">
                                                <h4 className="fw-bold"><Link to={`/service/${slug}`} style={{fontWeight: 'bold'}}>{item.title}</Link></h4>
                                                <p>{item.caption?.slice(0, 56) + (item.caption?.length > 56 ? "..." : "")}</p>
                                                <Link to={`/service/${slug}`} className="learn_more">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : <div className="col-md-12 text-center"><h3>No Record Found</h3></div>
                    }
                </div>
            </div>
            <div className="text-center mt-5">
                {
                    props.isHome ?
                        <Link to="/services" className="boxed-btn3-white-2">Explore More</Link> :<></>
                }
            </div>
        </div>
    )
}